<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        :sm="4"
        :md="4"
        v-show="!edition && !$route.params.cmd && !$route.params.of_id"
      >
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'mvms'"
          :AllResponse="'allmvm'"
          :title="title"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Mouvements'"
          :sheetname="'Mouvements'"
          @open="NewMvmForm"
          @rowselect="MvmChange"
          :showedit="false"
          :TypeScope="mvm_type"
          :VarScope="
            myBL == 'n' &&
            ($store.state.auth.includes('05021') || $store.state.isadmin)
              ? 1
              : 0
          "
          :btn1="
            mvm_type == 3 &&
            myBL == 'n' &&
            ($store.state.auth.includes('05021') || $store.state.isadmin)
              ? 'Composer BE'
              : ''
          "
          @click1="new_bon"
          :Add="editer"
          :del="editer && mvm_type == 5"
          :list_options="true"
          :search_elm="filtre"
          :key="klist"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="2" md="2" v-if="edition"> </v-col>
      <v-col cols="12" sm="8" md="8">
        <mvmprint
          :key="keymvm"
          :mvm="mvme"
          :mvm_type="mvm_type"
          :dossiers_list="dossiers_list"
          @change="DataUpdate"
          @delete="DataUpdate"
          :mvms_type="mvms_type"
          :open_orders="open_orders"
          :units="units"
          :adress_list="adress_list"
          :boxs="boxs"
          :editer="editer"
          :valider="valider"
          :solder="solder"
          :encours_list="encours_list"
          :agents="agents"
          :articles_fab="articles_fab"
          @cancel="mvme = selitem"
          :edition="edition"
          :attributes="attributes"
          @edition="edit_change"
          @refresh="get_data"
          @options="get_option"
          :data_loading="data_load"
          :ipg="options.itemsPerPage"
        >
        </mvmprint>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <formscinder :showform="!isListClose" :key="frml" @close="close_form">
    </formscinder>

    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-container>
</template>

<script>
import MVM from "../graphql/Mouvment/MVM.gql";
import MVMS_LIST from "../graphql/Mouvment/MVMS_LIST.gql";
import ALLMVMLIST from "../graphql/Mouvment/ALLMVMLIST.gql";
import MVMDATA from "../graphql/Mouvment/DATA.gql";
import PRODUCTION_LIST from "../graphql/Production/PRODUCTION_LIST.gql";
import UPDATE_MVM from "../graphql/Mouvment/UPDATE_MVM.gql";
import ACTUAL_ORDERS from "../graphql/Mouvment/ACTUAL_ORDERS.gql";
import ARTICLES from "../graphql/Commande/ARTICLES.gql";
import BOXS from "../graphql/Depot/BOXS.gql";
import ADRESSES from "../graphql/Tier/ADRESSES.gql";
import ACTUAL_DOSSIERS from "../graphql/Mouvment/ACTUAL_DOSSIERS.gql";
import { myBL } from "print/data.js";
//import ACTUAL_CMDS from "../graphql/Mouvment/ACTUAL_CMDS.gql";
import "jspdf-autotable";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    mvmprint: () => import("../components/MvmPrint.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    formscinder: () => import("../components/FormScinder.vue"),
  },
  props: {
    mvm_type: Number,
  },
  data: () => ({
    filtre: "",
    editer: false,
    solder: false,
    valider: false,
    edition: false,
    klist: 0,
    kmvm: 100,
    data_load: false,
    selitem: {},
    options: {},
    tier: {},
    headers: [
      {
        text: "Code",
        value: "nobon",
        enum: "NOBON",
        selected: true,
        width: "10",
      },
      {
        text: "Date",
        value: "opr_date",
        enum: "OPR_DATE",
        slot: "date",
        selected: true,
        width: "10",
      },
      {
        text: "Responsable",
        align: "text-left",
        value: "tier_name",
        enum: "TIERS_NAME",
        selected: true,
        width: "auto",
      },
      {
        text: "Valeur",
        value: "mont_ttc",
        enum: "mont_ttc",
        align: "end",
        slot: "cur",
        selected: false,
        hiden: true,
        width: "120",
      },

      {
        text: "Details",
        value: "countdetails",
        selected: false,
      },
      {
        text: "N° Facture",
        value: "nofact",
        selected: false,
        hiden: true,
      },
      {
        text: "Statut",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
      },
    ],
    tier_headers: [
      {
        text: "Code",
        value: "tier_code",
        enum: "TIER_CODE",
        selected: true,
      },
      {
        text: "Client",
        value: "tier_name",
        enum: "TIERS_NAME",
        selected: true,
      },
    ],

    isListClose: true,
    frml: 2000,
    articles_fab: [],
    encours_list: [],
    attributes: [],
    open_orders: [],
    mvms_type: [],
    agents: [],
    units: [],

    adress_list: [],
    dossiers_list: [],
    boxs: [],
    isMvmClosed: true,
    mvme: {},
    keymvm: 100,
    art_loading: false,
    changed: false,
    loading: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    title() {
      let t;
      let i = this.mvms_type.findIndex((elm) => elm.id == this.mvm_type);
      if (i >= 0) t = this.mvms_type[i].mouvment;
      return t;
    },
    myBL() {
      return myBL;
    },
    Qselect() {
      return MVMS_LIST;
    },
    Qselectall() {
      return ALLMVMLIST;
    },

    detail_list: function () {
      return this.mvme ? (this.mvme.id > 0 ? this.mvme.mvmdetails : []) : [];
    },
  },
  watch: {
    mvm_type: {
      handler() {
        this.aff++;
        this.mvme = {};
        this.keymvm++;
        this.klist++;
        this.get_data();
      },
      deep: true,
    },
    "$store.state.filtre": {
      handler() {
        this.filtre = this.$store.state.filtre;
        this.klist++;
      },
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$store.state.isEditing) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Quitter sans enregistrement !! Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        await this.update_modif();
        next();
      } else return;
    } else next();
  },
  created() {},

  mounted() {
    this.headers[2].text =
      this.mvm_type == 3 ||
      this.mvm_type == 4 ||
      this.mvm_type == 23 ||
      this.mvm_type == 19
        ? "Client"
        : this.mvm_type == 1 || this.mvm_type == 2 || this.mvm_type == 17
        ? "Fournisseur"
        : "Responsable";
    this.get_data();
    this.editer =
      (this.mvm_type == 1 && this.$store.state.auth.includes("02007")) ||
      (this.mvm_type == 2 && this.$store.state.auth.includes("02008")) ||
      (this.mvm_type == 3 && this.$store.state.auth.includes("02009")) ||
      (this.mvm_type == 4 && this.$store.state.auth.includes("02010")) ||
      (this.mvm_type == 23 && this.$store.state.auth.includes("02100")) ||
      (this.mvm_type == 17 && this.$store.state.auth.includes("02021")) ||
      (this.mvm_type == 5 && this.$store.state.auth.includes("02011")) ||
      (this.mvm_type == 5 && this.$store.state.auth.includes("02099")) ||
      (this.mvm_type == 6 && this.$store.state.auth.includes("02024")) ||
      (this.mvm_type == 7 && this.$store.state.auth.includes("02031")) ||
      (this.mvm_type == 8 && this.$store.state.auth.includes("02032")) ||
      (this.mvm_type == 9 && this.$store.state.auth.includes("02044")) ||
      (this.mvm_type == 10 && this.$store.state.auth.includes("02045")) ||
      (this.mvm_type == 11 && this.$store.state.auth.includes("02046")) ||
      (this.mvm_type == 12 && this.$store.state.auth.includes("02047")) ||
      (this.mvm_type == 14 && this.$store.state.auth.includes("02062")) ||
      (this.mvm_type == 15 && this.$store.state.auth.includes("02051")) ||
      (this.mvm_type == 16 && this.$store.state.auth.includes("02052")) ||
      (this.mvm_type == 18 && this.$store.state.auth.includes("02090")) ||
      (this.mvm_type == 19 && this.$store.state.auth.includes("02091")) ||
      (this.mvm_type == 21 && this.$store.state.auth.includes("02093")) ||
      (this.mvm_type == 22 && this.$store.state.auth.includes("02094")) ||
      this.$store.state.isadmin;

    this.valider =
      (this.mvm_type == 1 && this.$store.state.auth.includes("03007")) ||
      (this.mvm_type == 2 && this.$store.state.auth.includes("03008")) ||
      (this.mvm_type == 3 && this.$store.state.auth.includes("03009")) ||
      (this.mvm_type == 23 && this.$store.state.auth.includes("03100")) ||
      (this.mvm_type == 4 && this.$store.state.auth.includes("03010")) ||
      (this.mvm_type == 17 && this.$store.state.auth.includes("03021")) ||
      (this.mvm_type == 5 && this.$store.state.auth.includes("03011")) ||
      (this.mvm_type == 5 && this.$store.state.auth.includes("03099")) ||
      (this.mvm_type == 7 && this.$store.state.auth.includes("03031")) ||
      (this.mvm_type == 6 && this.$store.state.auth.includes("03024")) ||
      (this.mvm_type == 8 && this.$store.state.auth.includes("03032")) ||
      (this.mvm_type == 9 && this.$store.state.auth.includes("03044")) ||
      (this.mvm_type == 10 && this.$store.state.auth.includes("03045")) ||
      (this.mvm_type == 11 && this.$store.state.auth.includes("03046")) ||
      (this.mvm_type == 12 && this.$store.state.auth.includes("03047")) ||
      (this.mvm_type == 14 && this.$store.state.auth.includes("03062")) ||
      (this.mvm_type == 15 && this.$store.state.auth.includes("03051")) ||
      (this.mvm_type == 21 && this.$store.state.auth.includes("03093")) ||
      (this.mvm_type == 16 && this.$store.state.auth.includes("03052")) ||
      (this.mvm_type == 18 && this.$store.state.auth.includes("03090")) ||
      (this.mvm_type == 19 && this.$store.state.auth.includes("03091")) ||
      (this.mvm_type == 21 && this.$store.state.auth.includes("03093")) ||
      (this.mvm_type == 22 && this.$store.state.auth.includes("03094")) ||
      this.$store.state.isadmin;

    this.solder =
      (this.mvm_type == 1 && this.$store.state.auth.includes("04007")) ||
      (this.mvm_type == 2 && this.$store.state.auth.includes("04008")) ||
      (this.mvm_type == 3 && this.$store.state.auth.includes("04009")) ||
      (this.mvm_type == 23 && this.$store.state.auth.includes("04100")) ||
      (this.mvm_type == 4 && this.$store.state.auth.includes("04010")) ||
      (this.mvm_type == 17 && this.$store.state.auth.includes("04021")) ||
      this.$store.state.isadmin;

    this.headers[3].hiden = !(
      this.$store.state.auth.includes("01027") || this.$store.state.isadmin
    );
    this.headers[5].hiden = this.mvm_type > 4;
    //from payement
    if (this.$route.params.paye) {
      this.selitem = this.$route.params.paye;
      this.mvme = this.selitem;
      this.MvmChange(this.mvme);
      //this.keymvm++;
    }
    //from factures
    if (this.$route.params.mvm) {
      this.filtre = this.$route.params.mvm.nobon;
      this.selitem = this.$route.params.mvm;
      this.mvme = this.selitem;
      this.MvmChange(this.mvme);
      this.keymvm++;
      this.klist++;
    }
    //from commandes

    if (this.$route.params.cmd) {
      if (this.mvm_type <= 4 || this.mvm_type == 17 || this.mvm_type == 23) {
        this.mvme.cmd_id = this.$route.params.cmd.id;
        this.mvme.tier_id = this.$route.params.cmd.tier_id;
      }

      this.mvme.id = -1;
      this.edition = true;
      this.MvmChange(this.mvme);
    }
    //from OFs
    if (this.$route.params.of_id && this.rcpt_prod) {
      let i = this.open_orders.findIndex(
        (elm) => elm.id == this.$route.params.of_id
      );
      if (i >= 0) {
        this.of_id = this.$route.params.of_id;
        this.mvme.of_id = this.$route.params.of_id;

        this.mvme.id = -1;
        this.edition = true;
        this.MvmChange(this.mvme);
        this.$route.params.of_id = null;
      }
    }
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  methods: {
    new_bon() {
      this.isListClose = false;
      this.frml++;
    },
    async close_form(e) {
      if (e > 0) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en cours ! Etês-vous sûr de vouloir annuler?",
            ""
          )
        ) {
          this.isListClose = true;
        }
      } else this.isListClose = true;
    },
    option_change() {
      this.$nextTick(() => {
        this.mvme = {};
        this.kprint++;
      });
      this.klist++;
    },
    get_option(item) {
      this.options = item;
    },
    async preventNav(event) {
      if (!this.$store.state.isEditing) return;
      event.preventDefault();
      event.stopImmediatePropagation();

      //event.returnValue = "";
      await this.update_modif();
      //
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.mvme.id > 0) {
        let v = {
          mvm: {
            id: this.mvme.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_MVM, v);
      }
    },
    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
    edit_change(value) {
      this.edition = value;
    },

    async get_data() {
      let v = {
        TypeScope: 1,
      };
      let r = await this.requette(MVMDATA, v);
      if (r) {
        this.agents = r.agents;
        this.mvms_type = r.mvms_type;
        this.attributes = r.allattributes;
        this.units = r.units;
      }
      await this.DataUpdate();

      if (
        this.mvm_type == 18 ||
        (this.mvm_type == 19 && this.$store.state.options[0].rcpt_wo == 1)
      ) {
        v = {
          Type: this.$store.state.products_types.map((elm) => elm.id),
          tomanufacture: 1,
          tier_id: 1,
          tosell: this.mvm_type == 18 ? 0 : 1,
          service: "0",
          transformable: this.$store.state.isadmin ? null : "1",
          gamme_nb: this.mvm_type == 18 ? "0" : null,
          link_nb: this.mvm_type == 18 ? "0" : null,
          // linked: this.mvm_type == 19 ? "1" : null,
        };
        let r = await this.requette(ARTICLES, v);
        if (r) {
          this.articles_fab = r.articles;
        }
      }

      if (this.mvm_type == 1 || this.mvm_type == 3 || this.mvm_type == 17) {
        let r = await this.requette(ADRESSES);
        if (r) this.adress_list = r.adresses;
      }
      if (this.mvm_type == 7 || this.mvm_type == 8) {
        v = {
          where: {
            AND: [{ column: "STATUT_ID", value: 7 }],
          },
        };
        r = await this.requette(ACTUAL_ORDERS, v, "no-cache");
        if (r) this.open_orders = r.allorders;
      }
      r = await this.requette(BOXS, "", "no-cache");
      if (r) this.boxs = r.boxs;
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async DataUpdate(item) {
      if (item) {
        this.mvme = item;
        this.selitem = item;
      }
      this.MvmChange(item);

      if (this.mvm_type == 1) {
        let w = {
          AND: [
            { column: "STATUT_ID", value: 29 },
            { column: "QTE_RESTE", operator: "GT", value: 0 },
          ],
        };
        let v = {
          where: w,
        };
        let r = await this.requette(ACTUAL_DOSSIERS, v, "no-cache");
        if (r) {
          this.dossiers_list = r.allimports;
        }
      }
      if (this.mvm_type == 6 || this.mvm_type == 14 || this.mvm_type == 9) {
        let v;
        //produit fini
        if (this.mvm_type == 6)
          v = {
            receipt: 1,
            tosell: 1,
          };
        //matiere premiere
        if (this.mvm_type == 9)
          v = {
            receipt: 1,
            tosell: 0,
          };
        //non conforme
        if (this.mvm_type == 14) v = {};
        let r = await this.requette(PRODUCTION_LIST, v);
        if (r) this.encours_list = r.production_list;
      }
    },
    MvmDelete() {
      this.mvme = {};
    },
    async MvmChange(item) {
      if (item) {
        if (item.id > 0) {
          this.data_load = true;
          this.selitem = item;
          let r = await this.requette(MVM, {
            where: { column: "ID", value: item.id },
            exp: 1,
          });
          if (r) {
            this.mvme = r.mvms.data[0];
            this.keymvm++;
            this.data_load = false;
          }
        } else {
          this.mvme = {};
          this.selitem = this.mvme;
          this.keymvm++;
        }
      }

      // this.mvme = item;
      // this.selitem = item;
    },

    NewMvmForm(item) {
      this.edition = true;
      this.$store.dispatch("Editing", true);
      this.mvme = item;
      this.keymvm++;
    },
  },
};
</script>
